<template>
  <Property></Property>
</template>

<script>
import Property from "../components/Property.vue";

export default {
  name: "HotelListingItem",
  components: {
    Property,
  },
};
</script>
